import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useHistory } from 'react-router';
import { Ring } from 'react-awesome-spinners';
import axios from 'axios';
import Error from '../common/icons/Error';
import Modal, { ModalButton } from '../Modal';
import { Space } from '../common/Space';
import { RowSpaceBetween } from '../common/Common';
import TimerRecorder from './Timer/Timer';
import CallButton from './CallButton';
import TranscriptItem from './styled/TranscriptItem';
import TranscriptWrapper from './styled/TranscriptWrapper';
import AutoScroll from '../common/AutoScroll';
import { VOICE_CHAT_EVENTS, VoiceChat } from './VoiceChat';
import { BASE_URL, VOICE_ROUTER_URL } from '../../utils/config';
import { Wrappers, CallSimulatorWrapper } from './styled/Wrappers';
import {
    CloseButton,
    Description,
    HeaderWrapper,
    ProjectLabel,
    ProjectName,
    Title
} from './styled/Header';
import { CallSimulatorImg } from './styled/Icons';
import { FormGroup, SelectStyles } from './styled/Form';
import {
    CallSimulatorIntro,
    CallSimulatorNote,
    CallSimulatorTitle
} from './styled/Texts';
import CrossIcon from '../common/icons/CrossIcon';
import Headset from '../common/icons/Headset';
import { colors, config } from '../../utils';
import Warning from '../common/icons/Warning';
import { TRAINER_PAGE } from '../../utils/routes';
import { captureException } from '../../tracker/raven';
import { IS_LEGACY_LISTENER_MODE } from '../../config/electron';

const MODAL_EXIT = 'exit';
const MODAL_ENDED = 'ended';
const MODAL_LOADING = 'loading';
const MODAL_ERROR = 'error';

const EVALUATION_REQUEST_TIMEOUT = 2000;

export function CallSimulator({ localization, jwtToken }) {
    const projectList = ['GLF PREVENTION BTA'];
    const history = useHistory();

    const close = () => history.push(TRAINER_PAGE);

    const [modalOpen, setModalOpen] = React.useState(''); // ended / exit / loading
    const [isStarted, setIsStarted] = useState(false);
    const [projectRef, setProjectRef] = React.useState(projectList[0]);
    const [messages, setMessages] = useState([]);

    const initNewBot = () => {
        return new VoiceChat(VOICE_ROUTER_URL, jwtToken, {}, message => {
            setMessages(m => m.concat([message]));
        });
    };

    const voiceChat = useRef(initNewBot());
    const lastCallID = useRef(voiceChat.current.callID);

    useEffect(() => {
        if (!isStarted && jwtToken !== voiceChat.current.jwtToken) {
            voiceChat.current = new VoiceChat(VOICE_ROUTER_URL, jwtToken, {}, message => {
                setMessages(m => m.concat([message]));
            });
            lastCallID.current = voiceChat.current.callID;
        }
    }, [jwtToken, isStarted]);

    const toggleCall = () => {
        if (isStarted) {
            voiceChat.current.stop();
            setModalOpen(MODAL_ENDED);
        } else {
            voiceChat.current.start();
        }
    };

    useEffect(() => {
        voiceChat.current.on(VOICE_CHAT_EVENTS.STARTED, () => {
            setIsStarted(true);
            setMessages([]);
        });
        voiceChat.current.on(VOICE_CHAT_EVENTS.STOPPED, () => {
            setIsStarted(false);
            voiceChat.current = initNewBot();
        });
    }, [voiceChat.current]);

    const abController = useRef(undefined);

    const requestEvaluation = () => {
        setModalOpen(MODAL_LOADING);
        const templateId = 10;
        const callID = lastCallID.current;
        const endpoint = `evaluations/v1/agentevaluations/automated/templateid/${templateId}/callid/${callID}`;

        setTimeout(() => {
            abController.current = new AbortController();

            axios
                .post(
                    `${BASE_URL}/${endpoint}`,
                    {},
                    {
                        signal: abController.current.signal,
                        headers: {
                            Authorization: `Bearer ${jwtToken}`
                        }
                    }
                )
                .then(response => {
                    if (response?.data?.id) {
                        const dashboardUrl = config.getDashboardLink(
                            process.env.NODE_ENV
                        );
                        const params = encodeURIComponent(jwtToken);
                        const link = `${dashboardUrl}/login?token=${params}&redirect=${encodeURIComponent(
                            `/quality-management#${response?.data?.id}`
                        )}`;

                        window.open(link, !IS_LEGACY_LISTENER_MODE ? '_blank' : '_self');

                        setModalOpen(m => (m === MODAL_LOADING ? MODAL_ENDED : ''));
                    } else {
                        setModalOpen(m => (m === MODAL_LOADING ? MODAL_ERROR : ''));
                    }
                })
                .catch(error => {
                    captureException(error);
                    setModalOpen(m => (m === MODAL_LOADING ? MODAL_ERROR : ''));
                });
        }, EVALUATION_REQUEST_TIMEOUT);
    };

    return (
        <CallSimulatorWrapper>
            <Wrappers>
                <HeaderWrapper>
                    <Title>{localization.getText('call_simulator.title')}</Title>
                    <CloseButton onClick={() => setModalOpen(MODAL_EXIT)}>
                        <CrossIcon size={22} />
                    </CloseButton>
                </HeaderWrapper>
                {!isStarted ? (
                    <div
                        style={{
                            width: '100%'
                        }}>
                        <CallSimulatorImg />
                        <Space $size={20} />

                        <CallSimulatorTitle>
                            {localization.getText('call_simulator.welcome')}
                        </CallSimulatorTitle>
                        <Space $size={10} />

                        <CallSimulatorIntro>
                            {localization.getText('call_simulator.description')}
                        </CallSimulatorIntro>
                        <Space $size={20} />

                        <RowSpaceBetween
                            style={{
                                padding: '0 20px',
                                justifyContent: 'center'
                            }}>
                            <Headset size={30} />
                            <CallSimulatorNote>
                                {localization.getText('call_simulator.note1')}
                            </CallSimulatorNote>
                        </RowSpaceBetween>

                        <Space $size={20} />

                        <FormGroup
                            style={{
                                padding: '0 20px'
                            }}>
                            <label htmlFor="cs_project">
                                {localization.getText('call_simulator.project')}
                            </label>
                            <Select
                                isDisabled
                                value={{
                                    value: projectRef,
                                    label: projectRef
                                }}
                                inputId="cs_project"
                                options={projectList.map(c => {
                                    return { value: c, label: c };
                                })}
                                onChange={s => {
                                    setProjectRef(s?.value || '');
                                }}
                                isClearable={!!projectRef}
                                {...{
                                    maxMenuHeight: 150,
                                    styles: SelectStyles,
                                    isClearable: true,
                                    menuPlacement: 'auto'
                                }}
                            />
                        </FormGroup>
                    </div>
                ) : (
                    <>
                        <RowSpaceBetween>
                            <div>
                                <ProjectLabel>
                                    {localization.getText('call_simulator.project_name')}:
                                </ProjectLabel>
                                <ProjectName>{projectRef}</ProjectName>
                            </div>
                            <TimerRecorder />
                        </RowSpaceBetween>
                        <AutoScroll startAtBottom={isStarted}>
                            <TranscriptWrapper>
                                {messages.map((item, idx) => {
                                    const type = !item.isUser
                                        ? 'botResults'
                                        : 'agentResults';
                                    return (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <TranscriptItem className={type} key={`k${idx}`}>
                                            {item.text}
                                        </TranscriptItem>
                                    );
                                })}
                            </TranscriptWrapper>
                        </AutoScroll>
                    </>
                )}

                <CallButton
                    sendManualCallEvent={toggleCall}
                    isCallStarted={isStarted}
                    localization={localization}
                />
            </Wrappers>

            {modalOpen && (
                <Modal
                    title=""
                    borderRadius="20px"
                    overlayColor="rgba(69, 80, 230, 0.75)"
                    onClose={() => setModalOpen('')}>
                    {modalOpen === MODAL_ENDED && (
                        <Fragment>
                            <div
                                style={{
                                    textAlign: 'center'
                                }}>
                                <Warning size={72} color={colors.blue.blue_icons} />
                            </div>
                            <Space $size={20} />
                            <Title>
                                {localization.getText('call_simulator.modal_end.title')}
                            </Title>
                            <Space $size={6} />
                            <ul style={{ paddingLeft: '25px' }}>
                                {localization
                                    .getText('call_simulator.modal_end.description')
                                    .split('\n')
                                    .map(c => (
                                        <li key={c}>{c}</li>
                                    ))}
                            </ul>
                            <Space $size={6} />
                            <ModalButton
                                onClick={() => setModalOpen('')}
                                $color={colors.blue.i2x}
                                $disabledColor={colors.green.magic_mint}>
                                <span>
                                    {localization.getText(
                                        'call_simulator.modal_end.new_call'
                                    )}
                                </span>
                            </ModalButton>
                            <Space $size={6} />
                            <ModalButton
                                onClick={() => {
                                    requestEvaluation();
                                }}
                                $color={colors.gray.dark}
                                $isOutline
                                $disabledColor={colors.green.magic_mint}>
                                <span>
                                    {localization.getText(
                                        'call_simulator.modal_end.see_evaluation'
                                    )}
                                </span>
                            </ModalButton>
                            <Space $size={6} />
                            <ModalButton
                                onClick={() => close()}
                                $color={colors.gray.dark}
                                $isOutline
                                $disabledColor={colors.green.magic_mint}>
                                <span>
                                    {localization.getText(
                                        'call_simulator.modal_end.go_to_main'
                                    )}
                                </span>
                            </ModalButton>
                        </Fragment>
                    )}
                    {modalOpen === MODAL_EXIT && (
                        <Fragment>
                            <div
                                style={{
                                    textAlign: 'center'
                                }}>
                                <Warning size={72} color={colors.blue.blue_icons} />
                            </div>
                            <Space $size={20} />
                            <Title>
                                {localization.getText('call_simulator.modal_exit.title')}
                            </Title>
                            <Space $size={6} />
                            <Description>
                                {localization.getText(
                                    'call_simulator.modal_exit.description'
                                )}
                            </Description>
                            <Space $size={6} />
                            <ModalButton
                                onClick={() => close()}
                                $color={colors.blue.i2x}
                                $disabledColor={colors.green.magic_mint}>
                                <span>{localization.getText('call_simulator.yes')}</span>
                            </ModalButton>
                        </Fragment>
                    )}
                    {modalOpen === MODAL_LOADING && (
                        <Fragment>
                            <div
                                style={{
                                    textAlign: 'center'
                                }}>
                                <Ring color={colors.blue.blue_icons} />
                            </div>
                            <Space $size={6} />
                            <Description>
                                {localization.getText('call_simulator.loading_text')}
                            </Description>
                        </Fragment>
                    )}
                    {modalOpen === MODAL_ERROR && (
                        <Fragment>
                            <div
                                style={{
                                    textAlign: 'center'
                                }}>
                                <Error size={72} color={colors.red.pale} />
                            </div>
                            <Space $size={6} />
                            <Title>{localization.getText('call_simulator.error')}</Title>
                            <Space $size={40} />
                        </Fragment>
                    )}
                </Modal>
            )}
        </CallSimulatorWrapper>
    );
}

CallSimulator.propTypes = {
    localization: PropTypes.object.isRequired,
    jwtToken: PropTypes.string
};

CallSimulator.defaultProps = {
    jwtToken: ''
};
