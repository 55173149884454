import { SETTINGS_PAGE, CALL_SIMULATOR_PAGE } from '../../utils/routes';
import { messageTypes } from '../../utils/callHeader';
import { errorCodes } from '../../utils';

const {
    background: {
        AUDIO_DEVICE_STOPPED,
        UNEXPECTED_DAEMON_VERSION,
        AUTH_FAILED,
        MAIN_CONNECTION_CLOSED,
        CALL_DETECTION_STOPPED,
        AUDIO_DEVICES_CONFIG_CHANGED,
        AUDIO_SYSTEM_INFO_CONNECTION_CLOSED,
        RECOGNITION_CONNECTION_ERROR,
        HARDWARE_ERROR,
        SLOW_NETWORK,
        SLOW_NETWORK_WARN,
        EVENT_FORWARDING_CONNECTION_CLOSED,
        MAIN_CONNECTION_CLOSED_ON_START
    },
    settings: { NICK_NAME_ERROR },
    user: { PATCH_USER_PROFILE_FAILED }
} = errorCodes;

const en = {
    header: {
        titles: {
            call_summary: 'Call Summary',
            calling: 'Your current call',
            [SETTINGS_PAGE]: 'Settings',
            [CALL_SIMULATOR_PAGE]: 'Call simulator'
        },
        menu: {
            home: 'Home',
            settings: 'Settings',
            dashboard: 'Dashboard',
            sound_test: 'Sound test',
            snooze: 'Snooze',
            enable: 'Enable',
            logout: 'Sign out',
            call_simulator: 'Call simulator'
        },
        rank: {
            title: 'Your points this week!',
            message: 'Earn points by using words in the ‘do say’ list.'
        }
    },
    reconnect: {
        title: 'Reconnecting...',
        subtitle: "Lost connection. Don't panic!",
        retry: 'Reloading in %{countdown} seconds.'
    },
    trainer: {
        launch: 'Launch',
        empty_state: {
            title: 'Ready to make your next call?'
        },
        noDevicesFound: {
            message:
                'No active audio (input/output) device found. Please check your speaker and microphone setup.',
            title: 'No Active Audio Device',
            button: 'Retry'
        },
        receiving_sound_state: 'Receiving sound...',
        whitelist: {
            title: 'Do say',
            summary: {
                result: {
                    said: 'Said',
                    unsaid: 'Unsaid'
                },
                details: {
                    text:
                        'The list of detected words or phrases that you wanted to say more of.'
                }
            },
            stack: {
                list_label: 'Alternatives'
            }
        },
        blacklist: {
            title: "Don't say",
            empty_state: "Good job! Let's keep this empty!",
            summary: {
                empty_state: {
                    line_1: 'Clean track record!',
                    line_2: 'Keep it up!'
                }
            }
        },
        expand_button: {
            more: 'More',
            hide: 'Hide'
        },
        call_ratios: {
            loudness: 'Loudness',
            loudness_level: {
                quiet: 'Quiet',
                good: 'Good',
                loud: 'Loud'
            },
            speech_ratio: 'Speech Ratio',
            speech_rate: {
                title: 'Speech Rate',
                words_per_min: 'Words/Min',
                average: '(Ø %{average})'
            },
            speech_pace_level: {
                slow: 'Slow',
                good: 'Good',
                fast: 'Fast'
            },
            no_value: 'No value'
        },
        call_header: {
            summary: {
                title: {
                    default: 'Call ended'
                }
            },
            realtime: {
                overtime: 'Overtime'
            },
            status: {
                [messageTypes.HIGH]: [
                    "You're on a roll!",
                    'Nicely done!',
                    'Great job!',
                    'Keep it up!',
                    "You're doing great!",
                    "You're a pro!",
                    "You've got this!",
                    'You are amazing!'
                ],
                [messageTypes.REGULAR]: [
                    'Good effort!',
                    'Keep at it!',
                    'Pretty good!',
                    'You can do it!'
                ],
                [messageTypes.LOW]: ["You're getting there", 'Uh oh...', 'Keep training!']
            }
        },
        call_messages: {
            new_call: {
                top: 'New call detected!',
                bottom: 'Go for it!',
                bottomOptin: `Waiting for Opt-in confirmation...`
            },
            call_end: {
                top: 'Call finished!'
            },
            silence: 'Call paused or ended',
            sound_continued: 'Sound detected',
            not_started: 'Ready to make your next call?',
            key_phrase_integration_start_words: 'Call Start Keyword'
        },
        transcript: {
            copy_button: {
                to_copy: 'Copy Text',
                copied: 'Copied!'
            },
            expand_button: {
                show: 'See transcript',
                hide: 'Hide transcript'
            },
            name: {
                micResults: 'You',
                speakerResults: 'Client'
            },
            confidence: 'Confidence',
            pronunciation: 'Pronunciation'
        },
        call_popup: {
            last_call_score: 'Last call score!',
            current_call_score: 'Current score!',
            last_call: 'Last call',
            current_call: 'Current call',
            bonus_score: 'Bonus score!'
        },
        battle_card: {
            title: 'Keyword detected'
        },
        inactive: {
            temporary: {
                title: 'Snoozing...',
                subtext: 'The assistant is paused',
                limit: 'Limit reached! Turn me back on I can help'
            },
            permanent: {
                title: 'Your assistant is paused',
                subtext: 'Your calls are not being recorded',
                placeholderTitle: 'When your assistant is paused:',
                placeholderBody: `
                    <div>- you <b>won’t</b> see your performance in the dashboard</div>
                    <div>- you <b>won’t</b> collect points</div>
                    <div>- you <b>won’t</b> receive feedback from your manager</div>
                `
            },
            button: 'Go online',
            deactivate: 'Pause',
            resuming: 'Resuming...'
        },
        asr_config_errors: {
            no_keywords: `Do and Don’t Say keywords are not defined.`,
            no_team: `Oh oh, it seems that you are not part of a team yet. Please contact your team manager.`
        },
        asr_config_error_button: 'Reload',
        opt_in: {
            button: 'Opt-in'
        },
        opt_out: {
            button: 'Opt-out'
        },
        opt_in_out: {
            stop: 'STOP',
            record: 'RECORD',
            not_recorded_text: 'Customer is not being recorded.',
            recorded_text: 'Customer is being recorded...',
            opt_out_text: 'Customer is not recorded in this call.',
            record_stopped_text: 'Recording has been stopped.',
            confirm: 'Confirm',
            cancel: 'Cancel',
            opt_in_modal: {
                title: 'Did you receive consent from customer?',
                option1: 'Yes, I was given consent by customer to record in this call.',
                option2:
                    'I fully understand that recording customer without consent would result in legal consequences.'
            },
            opt_out_modal: {
                title: 'Please provide a reason why you want to stop recording.',
                option1: 'Customer asked to revoke their consent',
                option1_note: 'The recording will be deleted.',
                option2: 'Other reasons',
                option2_note: 'The recording will not be deleted.'
            }
        },
        callEventNotification: {
            call_start_phrase: 'The call recording is started.',
            call_restart_phrase: 'A new call recording is started.',
            call_end_phrase: 'The call recording is ended.',
            call_timeout: 'The call will be automatically ended soon.'
        }
    },
    settings: {
        title: 'Select a language:',
        languages: {
            en: 'English',
            de: 'German'
        },
        nickname: {
            title: 'Display Name:',
            note: 'Choose a name that your team lead can identify you with',
            success_msg: 'Your profile has been successfully updated!',
            welcome: 'Welcome',
            message: 'Ready to make your next call?',
            button: "Let's Go!"
        },
        inputDevice: {
            title: 'Microphone'
        },
        telephonyId: 'Telephony Login ID',
        error: {
            [NICK_NAME_ERROR]: `Unable to save the nickname. Please try later.`
        },
        timer: {
            title: 'Call Timer',
            subtitle: {
                enable:
                    'This will help you keep track of your call time and discover what works best over time. Maximum 60 minutes',
                disable: 'No timer has been set.'
            },
            minutes: 'Minute(s)',
            seconds: 'Second(s)',
            slider: {
                minutes: 'Minutes',
                seconds: 'Seconds'
            }
        },
        debug_info: {
            copy_button: 'Copy',
            empty: 'No information available.'
        }
    },
    sound_test: {
        start: {
            title: 'Sound Check',
            line_1:
                'Please say the words that appear on the next screen into your microphone.',
            buttons: {
                start: 'Start',
                exit: 'Cancel'
            }
        },
        ongoing: {
            time_remaining: 'Seconds remaining',
            buttons: {
                exit: 'Cancel'
            }
        },
        success: {
            title: 'YOU SOUND GREAT!',
            line: 'Everything works as normal.',
            buttons: {
                finish: 'FINISH'
            }
        },
        fail: {
            title: 'Ooops...',
            line_1: 'Something went wrong.',
            line_2: 'Please try again.',
            buttons: {
                finish: 'Cancel',
                retry: 'TRY AGAIN'
            }
        }
    },
    receiving_sound: {
        title: 'i2x is thinking...',
        subtitle: 'This will take a few seconds'
    },
    call_buttons: {
        start: 'Start',
        end: 'Stop'
    },
    errors: {
        [AUDIO_DEVICE_STOPPED]: `We can’t hear you. Error: ${AUDIO_DEVICE_STOPPED}`,
        [UNEXPECTED_DAEMON_VERSION]: `To use the latest version of i2x please restart your system. Error: ${UNEXPECTED_DAEMON_VERSION}`,
        [MAIN_CONNECTION_CLOSED]: `We can’t hear you. Error: ${MAIN_CONNECTION_CLOSED}`,
        [MAIN_CONNECTION_CLOSED_ON_START]: `We can’t hear you. Error: ${MAIN_CONNECTION_CLOSED_ON_START}`,
        [EVENT_FORWARDING_CONNECTION_CLOSED]: `We can’t hear you. Error: ${EVENT_FORWARDING_CONNECTION_CLOSED}`,
        [AUDIO_SYSTEM_INFO_CONNECTION_CLOSED]: `We can’t hear you. Error: ${AUDIO_SYSTEM_INFO_CONNECTION_CLOSED}`,
        [CALL_DETECTION_STOPPED]: `We're having connectivity issues. Our team is on the case. Error: ${CALL_DETECTION_STOPPED}`,
        [RECOGNITION_CONNECTION_ERROR]: `We're having connectivity issues. Our team is on the case. Error: ${RECOGNITION_CONNECTION_ERROR}`,
        [AUDIO_DEVICES_CONFIG_CHANGED]: `Your audio setup has changed. Error: ${AUDIO_DEVICES_CONFIG_CHANGED}`,
        [AUTH_FAILED]: `Something went wrong during authentication. If the issue persists, please contact your system administrator. Error: ${AUTH_FAILED}`,
        [HARDWARE_ERROR]: `Something went wrong with the sound hardware on your computer. Please contact your system administrator. Error: ${HARDWARE_ERROR}`,
        [SLOW_NETWORK]: `The network connection is too slow. We have to reload the application. If the issue persists, please contact your system administrator. Error: ${SLOW_NETWORK}`,
        [PATCH_USER_PROFILE_FAILED]:
            'It seems that we have some trouble with your request...'
    },
    warnings: {
        [SLOW_NETWORK_WARN]: `Slow internet connection detected. Real-time analytics might not be fully functional.`
    },
    no_telephony_id:
        'No telephony Login ID is assigned to your account, please contact your team manager.',
    login: {
        title: 'Login',
        UnknownError: 'Unknown error.',
        NoNetwork: 'Please check your credentials or try again later.',
        InvalidCredentials: 'Please check your credentials or try again later.',
        username: 'Username / E-Mail',
        password: 'Password',
        locationName: 'Location Name',
        doLogin: 'Sign in',
        troubleSigningIn: 'Trouble signing in? Forgot password?',
        contactManager: 'Please contact your manager.'
    },
    conversion: {
        title: 'Did a successful sale, contract extension, upsell or cross-sells happen?',
        result: {
            yesOne: 'Yes, only one',
            yesMultiple: 'Yes, more than one',
            no: 'No'
        },
        submitButtonText: 'Submit'
    },
    callSuggestions: {
        title: 'Performance Feedback',
        numberOfCalls: 'Based on previous %{calls} calls',
        lastUpdate: 'Last update'
    },
    tabs: {
        assistance: 'Assistance',
        sales: 'Sales'
    },
    sales: {
        agent: 'Agent',
        loading: 'LOADING...',
        campaign: 'Project',
        customer: 'Customer / Contract ID (optional)',
        category: 'Category',
        product: 'Product',
        call_id: 'Call ID',
        date_time: 'Date and Time',
        send: 'SEND',
        close: 'CLOSE',
        cancel: 'CANCEL and EDIT',
        confirm: 'CONFIRM',
        modal: {
            try_again: 'Please try again.',
            error: 'Oops, an error occurred',
            good_work: 'Good Work!',
            successfully_uploaded:
                'The sales information has been successfully uploaded.',
            title: 'Sales Summary',
            desc: 'You can see your sales details below.'
        }
    },
    call_simulator: {
        title: 'Call Simulator',
        welcome: 'Welcome to Call Simulator',
        description:
            'Develop your skills in a safe environment before handling real calls. Our voice bot simulates realistic scenarios, helping you build confidence, refine communication, and excel when you go live.',
        note1: 'Please use headset to eliminate echo.',
        project: 'Project',
        project_name: 'Project name',
        modal_exit: {
            title: 'Do you want to exit the Call Simulator?',
            description:
                'If you leave before the discussion ends, this session will not be evaluated.'
        },
        modal_end: {
            title: 'The call has ended!',
            description:
                'You can start a new call in the Call Simulator.\n' +
                'You can view the evaluation of the call.\n' +
                'You can return to the start page.',
            new_call: 'START A NEW CALL',
            go_to_main: 'GO TO MAIN PAGE',
            see_evaluation: 'SEE YOUR EVALUATION'
        },
        loading_text: 'Please wait. An evaluation of your call is in process.',
        yes: 'Yes',
        error: 'Oops! Something went wrong.'
    }
};

export default en;
