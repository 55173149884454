import ls from 'store2';
import { IS_UI_ONLY_MODE } from '../config/electron';

const debug = ls.namespace('debug');

export const ENV = {
    DEVELOPMENT: 'development',
    PRODUCTION: 'production'
};

export const API_BASE_URLS = {
    [ENV.DEVELOPMENT]: 'https://api-dev.eu.i2x.ai',
    [ENV.PRODUCTION]: 'https://api.eu.i2x.ai'
};

export const LOGGER_API_KEYS = {
    [ENV.DEVELOPMENT]: '62eb165c070a41d5c1b58d9d3d725ca1',
    [ENV.PRODUCTION]: '88ecafb959164a39aeb785adce874d96'
};

export const RECOGNITION_ENDPOINT = {
    [ENV.PRODUCTION]: {
        protocol: 'wss',
        host: IS_UI_ONLY_MODE ? 'api.eu.i2x.ai/asr/v2' : 'api.eu.i2x.ai/asr/v1'
    },
    [ENV.DEVELOPMENT]: {
        protocol: 'wss',
        host: IS_UI_ONLY_MODE ? 'api-dev.eu.i2x.ai/asr/v2' : 'api-dev.eu.i2x.ai/asr/v1'
    },
    debug: {
        protocol: debug.get('asrConnectionProtocol', false),
        host: debug.get('asrConnectionHost', false),
        port: debug.get('asrConnectionPort', false)
    }
};

export const CORE_DASHBOARD_URLS = {
    [ENV.DEVELOPMENT]: 'https://insights-dev.i2x.ai',
    [ENV.PRODUCTION]: 'https://insights.i2x.ai'
};

const VOICE_ROUTER_URLS = {
    [ENV.DEVELOPMENT]: 'wss://bot.ml4.i2x.ai/simulator/v2-dev',
    [ENV.PRODUCTION]: 'wss://bot.ml4.i2x.ai/simulator/v2'
};

const getBases = (env = ENV.DEVELOPMENT) => {
    return API_BASE_URLS[env];
};

export const getAsrEndpoint = (env = process.env.NODE_ENV || ENV.DEVELOPMENT) => {
    return RECOGNITION_ENDPOINT[env];
};

export const getDashboardLink = (env = process.env.NODE_ENV || ENV.DEVELOPMENT) => {
    return CORE_DASHBOARD_URLS[env];
};

export const BASE_URL = getBases(process.env.NODE_ENV);
export const LOGGER_API_KEY = LOGGER_API_KEYS[process.env.NODE_ENV];
export const CALL_EVENT_URL = `${getBases(process.env.NODE_ENV)}/events/v1/calls`;
export const VOICE_ROUTER_URL = VOICE_ROUTER_URLS[process.env.NODE_ENV];
