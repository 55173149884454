import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Assessment from '../common/icons/Assessment';
import { SuggestionsButton } from './styled/SuggestionsButton';
import {
    LogoIcon,
    Wrapper,
    MenuWrapper,
    DurationWrapper,
    Dot,
    StatusIndicator,
    RecordingWrapper
} from './styled';
import Menu from './Menu';
import Rank from './Rank';
import { colors, routes } from '../../utils';
import { formatTime } from '../../utils/date';
import Placeholder from '../common/Placeholder';

class Header extends Component {
    static propTypes = {
        elapsedSeconds: PropTypes.number,
        location: PropTypes.object.isRequired,
        localization: PropTypes.object.isRequired,
        hasError: PropTypes.bool.isRequired,
        showBoxShadow: PropTypes.bool.isRequired,
        userScore: PropTypes.object,
        userScoreError: PropTypes.object,
        whitelistPhrasesSpottingScore: PropTypes.object,
        isTrainerActive: PropTypes.bool.isRequired,
        isUpdatingTrainerStatus: PropTypes.bool,
        displaySnoozingControls: PropTypes.bool.isRequired,
        displayOnlineButton: PropTypes.bool.isRequired,
        resumeTrainer: PropTypes.func.isRequired,
        snoozeTrainer: PropTypes.func.isRequired,
        setInfinitySnooze: PropTypes.func.isRequired,
        showDashboardLink: PropTypes.bool.isRequired,
        isVodafone: PropTypes.bool,
        optInOn: PropTypes.bool.isRequired,
        isOptOut: PropTypes.bool.isRequired,
        manualOptIn: PropTypes.func.isRequired,
        manualOptOut: PropTypes.func.isRequired,
        doLogout: PropTypes.func,
        isCallStarted: PropTypes.bool.isRequired,
        displayCallOptOutControls: PropTypes.bool,
        recordCall: PropTypes.bool,
        authWithPasswordRequired: PropTypes.bool,
        showMenu: PropTypes.bool,
        authToken: PropTypes.string,
        showSuggestionsButton: PropTypes.bool,
        toggleCallSuggestions: PropTypes.func.isRequired,
        isCallSuggestionsNew: PropTypes.bool.isRequired,
        enableTrainer: PropTypes.bool.isRequired,
        enableCallSimulator: PropTypes.bool.isRequired,
        enableSales: PropTypes.bool.isRequired
    };

    static defaultProps = {
        elapsedSeconds: null,
        userScore: null,
        userScoreError: null,
        whitelistPhrasesSpottingScore: null,
        isUpdatingTrainerStatus: false,
        isVodafone: false,
        displayCallOptOutControls: false,
        recordCall: true,
        authWithPasswordRequired: false,
        showMenu: true,
        doLogout: undefined,
        authToken: undefined,
        showSuggestionsButton: false
    };

    state = {};

    _renderDuration() {
        const { elapsedSeconds, isCallStarted } = this.props;
        const duration = formatTime(elapsedSeconds);
        if (!duration || !isCallStarted) return;

        return (
            <DurationWrapper $disabled={false}>
                <span>{duration}</span>
            </DurationWrapper>
        );
    }

    _renderRecording() {
        const { isCallStarted, recordCall } = this.props;
        const { displayRecordingState } = window;
        if (!isCallStarted) return null;
        if (!recordCall) return null;
        if (!displayRecordingState) return null;
        return (
            <div
                style={{
                    width: 12,
                    height: 12,
                    background: 'red',
                    borderRadius: 12
                }}
            />
        );
    }

    _renderLogoNavigation() {
        return (
            <NavLink to={routes.TRAINER_PAGE}>
                <LogoIcon />
            </NavLink>
        );
    }

    _renderSpinner() {
        return (
            <div>
                <Placeholder width="22px" />
            </div>
        );
    }

    _renderUserScores() {
        const { userScore, whitelistPhrasesSpottingScore, localization } = this.props;

        return (
            <Rank
                {...userScore}
                whitelistPhrasesSpottingScore={whitelistPhrasesSpottingScore}
                localization={localization}
            />
        );
    }

    _renderLogo = () => {
        const {
            location: { pathname },
            userScore,
            userScoreError,
            isTrainerActive
        } = this.props;

        if (pathname !== routes.TRAINER_PAGE) return this._renderLogoNavigation();

        if (!userScoreError && userScore == null && isTrainerActive) {
            return this._renderSpinner();
        }

        if (userScore && this.props.enableTrainer) return this._renderUserScores();

        return <LogoIcon />;
    };

    _renderOnlineButton = () => {
        if (!this.props.displayOnlineButton) {
            return null;
        }

        return (
            <div>
                <StatusIndicator onClick={() => this.props.setInfinitySnooze()}>
                    <span>Online</span>
                    <Dot>
                        <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.30536 0L10 1.49647L5.4996 5.47059C5.10791 5.81647 4.59632 6 4.04476 6C3.49321 6 2.97362 5.80941 2.58993 5.46353L0 3.18353L1.69464 1.68706L4.04476 3.76235L8.30536 0Z"
                                fill="white"
                            />
                        </svg>
                    </Dot>
                </StatusIndicator>
            </div>
        );
    };

    render() {
        const {
            hasError,
            localization,
            showBoxShadow,
            displaySnoozingControls,
            isTrainerActive,
            resumeTrainer,
            snoozeTrainer,
            showDashboardLink,
            setInfinitySnooze,
            showMenu,
            authToken,
            doLogout,
            authWithPasswordRequired,
            showSuggestionsButton,
            isCallSuggestionsNew,
            enableCallSimulator
        } = this.props;

        return (
            <Wrapper $showBoxShadow={showBoxShadow}>
                <div>{this._renderLogo()}</div>
                <RecordingWrapper>
                    {this._renderDuration()}
                    {this._renderRecording()}
                </RecordingWrapper>
                {this._renderOnlineButton()}
                <div>
                    {showSuggestionsButton && (
                        <SuggestionsButton onClick={this.props.toggleCallSuggestions}>
                            <Assessment size={22} color={colors.black.nero} />
                            {isCallSuggestionsNew && <div className="red-dot" />}
                        </SuggestionsButton>
                    )}
                    {showMenu && (
                        <MenuWrapper>
                            <Menu
                                localization={localization}
                                disabled={hasError}
                                displaySnoozingControls={displaySnoozingControls}
                                isTrainerActive={isTrainerActive}
                                resumeTrainer={resumeTrainer}
                                snoozeTrainer={snoozeTrainer}
                                showDashboardLink={showDashboardLink}
                                setInfinitySnooze={setInfinitySnooze}
                                authToken={authToken}
                                doLogout={doLogout}
                                authWithPasswordRequired={authWithPasswordRequired}
                                enableCallSimulator={enableCallSimulator}
                            />
                        </MenuWrapper>
                    )}
                </div>
            </Wrapper>
        );
    }
}

export const PureHeader = Header;
export default Header;
